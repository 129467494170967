

/***************************************/
/**********Besic Set up*****************/
/***************************************/

*
{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

html,body
{   
    font-family: 'Montserrat', sans-serif;
    font-family: 'Raleway', sans-serif;

font-size: 62.5%;
text-rendering: optimizeLegibility;


background-color: rgb(248, 249, 250);

}

.row
{
    width: 125rem;
    margin: auto;
}

section
{
    overflow: hidden;
    padding: 5rem 0rem;
}


/***************************************/
/**********Hero Section*****************/
/***************************************/

.hero-section
{
    width: 100%;
    height: 90vh;
    background-image: linear-gradient(rgb(30, 35, 47,0.7),rgb(30, 35, 47,0.99)),url('../image/hero-bg9.jpg');
    
    background-size:cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;

}

.hero-grid
{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 3rem 4rem;


    margin-top: 15rem;
}

.hero-image .hero-img
{
    width: 100%;
}
.hero-image
{
    width: 80%;
    margin-left: 5rem;

}



.hero-grid-right
{
    display: flex;
    align-items: center;
}


.hero-intro p
{
    font-family: 'Montserrat', sans-serif;
    color: rgba(255, 255, 255, 0.929);
    font-size: 1.8rem;
    text-align: justify;
}

.hero-btn
{
    display: inline-block;
    color: white;
    margin-top: 3.4rem;
    font-size: 2.5rem;
    text-decoration: none;
    padding: 0.7rem 1.8rem;
    background-color: #EC595A;
    transition: 0.3s;
    border-radius: 0.2rem ;
}







/***************************************/
/**********Navigation Section*****************/
/***************************************/
.navigation-background
{
    background-color: transparent !important;
    padding: 1rem 4rem;
}

.navigation-brand
{
    color: white;
    text-decoration: none;
    font-size: 3.2rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    border-bottom: 0.2rem solid  rgb(226, 30, 81);
    
}
.navigation-brand:hover
{
    color: white;
}


.navigation-list
{
    font-size: 1.6rem;
    text-decoration: none;
    color: white;
    margin-left: 2rem;
    margin-right: 2rem;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    transition: 1.5s;

}

.navigation-list:hover
{
    color: rgb(226, 30, 81);
    border-bottom: 0.2rem solid  rgb(226, 30, 81);
    
}


.navigation-background-scroll
{
    background-color: rgba(30, 35, 47, 0.493);
    border-bottom: 0.1rem solid white;
    padding: 1rem 4rem;
}


.navigation-icon
{
    font-size: 2rem;
    margin-right: 0.7rem;
    margin-bottom: 0.5rem;
    display: inline-block;
}




/***************************************/
/**********mission Section*****************/
/***************************************/



.mission-section h2
{
    text-align: center;
    color: #171717;
    font-size: 3rem;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    
}

.mission-section h2::after
{
    content: '';
    width: 13rem;
    height: 0.4rem;
    display: block;
    background-color:#03AEF2;
    margin: auto;
    border-radius: .5rem;
    margin-top: 1.4rem;
}

.mission-section .mission-grid
{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 3rem 4rem;

    margin-top: 4rem;
}

.mission-section .mission-img
{
   width: 100%; 
}

.mission-section .mission-image
{
width: 90%;
margin: auto;


}

.mission-section .mission-img:hover
{
    transform: scale(1.04);
    transition: 0.5s;

}

.mission-section .mission-grid .mission-des p
{
    font-size: 1.5rem;
    font-family: 'Montserrat', sans-serif;
    text-align: justify;
}



/***************************************/
/**********vision Section*****************/
/***************************************/
.vision-section
{
    background-color: white;
}

.vision-section h2
{
    text-align: center;
    color: #171717;
    font-size: 3rem;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    
}

.vision-section h2::after
{
    content: '';
    width: 13rem;
    height: 0.4rem;
    display: block;
    background-color:#03AEF2;
    margin: auto;
    border-radius: .5rem;
    margin-top: 1.4rem;
}

.vision-section .vision-grid
{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 3rem 4rem;

    margin-top: 4rem;
}

.vision-section .vision-img
{
   width: 100%; 
}

.vision-section .vision-image
{
width: 90%;
margin: auto;

}

.vision-section .vision-img:hover
{
    transform: scale(1.04);
    transition: 0.5s;

}

.vision-section .vision-grid .vision-des p
{
    font-size: 1.5rem;
    font-family: 'Montserrat', sans-serif;
    text-align: justify;
}



/***************************************/
/**********Product Section************/
/***************************************/

.product-section h2
{
    text-align: center;
    color: #171717;
    font-size: 3rem;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    
}

.product-section h2::after
{
    content: '';
    width: 17rem;
    height: 0.4rem;
    display: block;
    background-color:#03AEF2;
    margin: auto;
    border-radius: .5rem;
    margin-top: 1.4rem;
}

.product-section .mission-grid
{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 3rem 4rem;

    margin-top: 4rem;
}

.product-section .mission-img
{
   width: 100%; 
}

.product-section .mission-image
{
width: 90%;
margin: auto;


}

.product-section .mission-img:hover
{
    transform: scale(1.04);
    transition: 0.5s;

}

.product-section .mission-grid .mission-des p
{
    font-size: 1.5rem;
    font-family: 'Montserrat', sans-serif;
    text-align: justify;
}

.bold
{
    font-weight: 500;
}

.product-section .mission-grid .mission-des ul
{
    font-size: 1.5rem;
    list-style-type:circle;
}



/***************************************/
/*********Client Section************/
/***************************************/
.client-section
{
    background-color: white;
}

.client-section h2
{
    text-align: center;
    color: #171717;
    font-size: 3rem;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    
}

.client-section h2::after
{
    content: '';
    width: 17rem;
    height: 0.4rem;
    display: block;
    background-color:#03AEF2;
    margin: auto;
    border-radius: .5rem;
    margin-top: 1.4rem;
}

.client-des p
{
    font-size: 1.7rem;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 6rem;
}


.client-brand-img
{
    width: 70%;
}

.client-section .client-slider
{
    width: 90% !important;
    
    margin-left: 2rem;
    margin: auto;

    margin-bottom: 3rem;
}


.slick-slider .slick-list, .slick-slider .slick-track
{
    margin-bottom: 3rem;
}
.slick-dots
{
    margin-bottom: 1rem;
}





/***************************************/
/*********Location Section************/
/***************************************/

.location-section h2
{
    text-align: center;
    color: #171717;
    font-size: 3rem;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    
}

.location-section h2::after
{
    content: '';
    width: 20rem;
    height: 0.4rem;
    display: block;
    background-color:#03AEF2;
    margin: auto;
    border-radius: .5rem;
    margin-top: 1.4rem;
}

.map
{
    margin-top: 4rem;
}







/***************************************/
/**********Footer section************/
/***************************************/
.footer-section
{
    background-color: #2d343af3;
    background-image: linear-gradient(to left bottom,rgb(45, 52, 58,0.7),rgb(45, 52, 58)),url('../image/footerMap.png');
    background-size: contain;
    background-position: center;
}

.footer-grid
{
  display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 3rem 3rem;

}

.footer-grid p
{
    font-size: 1.5rem;
    color: rgba(255, 255, 255, 0.67);
    font-family: 'Montserrat', sans-serif;

}

.footer-grid h3
{
    font-size: 2rem;
    color: white;
    margin-bottom: 1.5rem;
}


 


.footer-grid ul
{
     list-style-type: none;
     overflow: hidden;
     margin: 0rem;
     padding: 0rem;
}

.footer-grid ul li
{
     display: inline-block;
}
.footer-grid ul li a
{
   
    display: inline-block;
    text-decoration: none;
    color:white;
    font-size: 2rem;
    transition: 0.3s;     
    padding: 0.5rem 1rem;   
    
    margin-left: 3rem;
    border-radius: 0.3rem;
}

.footer-grid ul li a:hover
{
    background-color: white;
    color: black;
}

.footer-grid ul .facebook
{
    margin: 0rem !important;
}



.footer-section-bottom
{
    background-color: #1D2125;
    width: 100%;
    padding: 3rem 0rem;
    
}

.footer-section-bottom p
{
    font-size: 1.6rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    text-align: center;
    color: white;
}




/***************************************/
/*********Every Page Top Section************/
/***************************************/


.everyPageTop-section
{
    width: 100%;
    height: 45vh;
    background-image: linear-gradient(rgb(30, 35, 47,0.8),rgb(30, 35, 47,0.9)),url('../image/hero-bg9.jpg');
    
    background-size: cover;
    background-position: top right;
    background-attachment: fixed;

    text-align: center;
}

.everyPageTop-section h2
{
    font-family:'Raleway', sans-serif;
    font-size: 4rem;
    font-weight: 700;
    margin-top: 8rem;
    color: white;
    padding: 2rem;

    border-bottom: 0.2rem solid rgb(226, 30, 81);
}








/***************************************/
/*************About Section************/
/***************************************/
.about-section
{
   
    
   background-color: white;
}
.about-grid
{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 3rem 4rem;

    margin-top: 4rem;
}


.about-section .about-img
{
   width: 100%; 
}

.about-section .about-image
{
width: 80%;
margin: auto;

}
.about-grid h2
{
    font-size: 3rem;
    font-family: 'Montserrat', sans-serif;
    color: #03AEF2;
    font-weight: 500;
    margin-bottom: 2rem;
}
.about-section .about-img:hover
{
    transform: scale(1.04);
    transition: 0.5s;

}

.about-section .about-grid  p
{
    font-size: 1.5rem;
    font-family: 'Montserrat', sans-serif;
    text-align: justify;
}









/***************************************/
/**********Factory Info section************/
/***************************************/

.factoryInfo-section
{
   
    
   background-color: white;
}
.factory-des p
{
    font-size: 1.7rem;
    text-align: justify;
    font-family: 'Montserrat', sans-serif;

}
.factoryInfo-section h2
{
    text-align: center;
    color: #171717;
    font-size: 3rem;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    text-transform: uppercase;

    margin-top: 4rem;
    
}


.factoryInfo-section h2::after
{
    content: '';
    width: 20rem;
    height: 0.3rem;
    display: block;
    background-color:#03AEF2;
    margin: auto;
    border-radius: .5rem;
    margin-top: 1.4rem;
}
.factoryInfo-grid
{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 6rem 4rem;

    margin-top: 5rem;
}

.factoryInfo-grid .col
{
    box-shadow: 0.5rem 0.5rem 0.5rem #17171736;
    border:0.1rem solid rgba(128, 128, 128, 0.132);
    padding:2rem 1rem;
    border-radius: 0.5rem;
    background-color: #F1F3F4;
    transition: 0.3s;
}

.factoryInfo-grid .col:hover
{
   
    box-shadow: -0.5rem -0.5rem 0.5rem #17171736;
}

.factoryInfo-img
{
    width: 100%;

}
.factoryInfo-img:hover
{

    transform: scale(1.2);
    transition: 0.7s;
}

.factoryInfo-image
{
    width: 90%;
    margin: auto;
    overflow: hidden;
}

.factoryInfo-grid h3
{
    font-size: 2.5rem;
    margin: 1rem 0rem;
    text-align: center;
}

.factoryInfo-grid p
{
    font-size: 1.3rem;
    font-family: 'Montserrat', sans-serif;
}
.factoryInfo-grid ul li
{
    font-size: 1.3rem;
    font-family: 'Montserrat', sans-serif;
}
.factoryInfo-grid ul
{
   list-style-type: circle;
}










.Social-Responsibilities-section
{
background-color: white;
}

.Compliance-with-Standards-section
{
    background-color: #F8F9FA;
}


/***************************************/
/**********certification-section************/
/***************************************/



.certification-section
{
    background-color: white;
}



.certification-section h2
{
    text-align: center;
    color: #171717;
    font-size: 3rem;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    
}

.certification-section h2::after
{
    content: '';
    width: 13rem;
    height: 0.4rem;
    display: block;
    background-color:#03AEF2;
    margin: auto;
    border-radius: .5rem;
    margin-top: 1.4rem;
}

.certification-section .certificate-text-box
{
    font-size: 1.7rem;
    text-align: justify;
    margin-top: 4rem;
}


.logo-box
{
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: repeat(4,1fr);
    margin-top: 4rem;
}

.certificate-img
{
    width: 100%;
}

.certificate-image
{
width: 50%;
margin: auto;

}  












/***************************************/
/**********Gallery Section*****************/
/***************************************/
.gallery-section
{
    background-color: #F8F9FA;
}

.gallery-section h2
{
    text-align: center;
    color: #171717;
    font-size: 3rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
}

.gallery-section h2::after
{
    content: '';
    width: 10rem;
    height: 0.4rem;
    display: block;
    background-color:#03AEF2;
    margin: auto;
    border-radius: .5rem;
    margin-top: 1.4rem;
    
}

.gallery-section .gallery-grid
{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 3rem 2rem;
    
    margin-top: 6rem;
}

.gallery-section .gallery-img
{
    width: 350px;
    height: 350px;
    border-radius: 0.5rem;

    outline-width: 0.1rem;
    outline-style: solid;
    outline-color: #9491918b;
    outline-offset: 1rem;
}

.gallery-section .gallery-img:hover
{
    transform: scale(1.06);
    transition: 0.3s;

    filter: saturate(2);
}








/***************************************/
/**********Contact Section************/
/***************************************/

.grid-contact
{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 3rem 4rem;
}

.contact-section h2
{
    color: #1A202C;
    font-family: 'Montserrat', sans-serif;
    font-size: 3.2rem;
    font-weight: 600;
    text-transform: capitalize;
}

.contact-title
{
    color:#E21E51 ;
    font-size: 2.3rem;
    word-spacing: 0.5rem;
    font-family: 'Raleway', sans-serif;
    margin-top: 10rem;
    text-transform: uppercase;
    font-weight: 400;
    
}

.grid-contact form
{
   
    background-color: #EFEFF5;
    padding: 4rem;
    border-radius: 1rem;
    
}


.contact-name input
{
    background-color: #F8F9FA;
    width: 100%;
    height: 3.8rem;
    font-size: 1.6rem;
    border:0rem;
    padding: 0.9rem;
    
    
}

.contact-name input::placeholder
{
    color: #E21E51;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;  
}
.contact-email input
{
    background-color: #F8F9FA;
    width: 100%;
    height: 3.7rem;
    font-size: 1.6rem;
    margin-top: 1.7rem;
    border:0rem;
    padding: 0.9rem;
}
.contact-email input::placeholder
{
    color: #E21E51;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;  
}

.contact-msg textarea
{
    background-color: #F8F9FA;
    width: 100%;
    height: 20rem;
    font-size: 1.6rem;
    margin-top: 1.7rem;
    border:0rem;
    padding: 0.9rem;
}

.contact-msg textarea::placeholder
{
    color: #E21E51;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;  
}

.contact-btn input
{
    display: inline-block;
    color: white;
    margin-top: 1.4rem;
    font-size: 1.6rem;
    text-decoration: none;
    padding: 0.8rem 1.7rem;
    background-color: rgb(226, 30, 81) ;
    border:0rem;
}
.contact-section marquee
{
    color:rgb(226, 30, 81) ;
    font-size: 1.9rem;
    margin-bottom: 4rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    
}






/***************************************/
/**********Responsive Design************/
/***************************************/


@media screen and (max-width:1200px)
{
    .row
    {
        width: 100%;
        padding: 0rem 4rem;
    }

   
}

@media screen and (max-width:1024px)
{
    

    .navigation-background
    {
        background-color: rgba(30, 35, 47, 0.493) !important;
        text-align:center;
    }

    .navigation-background-scroll
    {
        text-align:center;
    }

    .hero-image
    {
        width: 90%;
        margin-left: 5rem;

    }

    .factoryInfo-grid
    {
        grid-template-columns: repeat(2,1fr);
    }

    
    .gallery-section .gallery-grid
    {
        display: grid;
        grid-template-columns: auto auto;
        
        justify-content: space-around ;
        
    }
    
    .gallery-section .gallery-img
    {
        width: 300px;
        height: 300px;
    }

    
}

@media screen and (max-width:768px)
{
    html,body
    {   
    font-size: 50.5%;
    }

    .hero-grid
    {
        grid-template-columns: repeat(1,1fr);
    
        grid-gap: 8rem;
    }
    .hero-image
    {
        width: 80%;
        margin: auto;

    }

    .mission-section .mission-grid
    {
        display: grid;
        grid-template-columns: repeat(1,1fr);
    }

    .vision-section .vision-grid
    {
        display: grid;
        grid-template-columns: repeat(1,1fr);
    }

    .product-section .mission-grid
    {
        display: grid;
        grid-template-columns: repeat(1,1fr);
    }

    .footer-grid
    {
        grid-template-columns: repeat(2,1fr);

    }

    .about-grid
    {
        grid-template-columns: repeat(1,1fr);
        
    }
    .factoryInfo-grid
    {
        grid-template-columns: repeat(1,1fr);
    }
    .logo-box
    {
        grid-template-columns: repeat(2,1fr);
    }

    .gallery-section .gallery-img
    {
        width: 250px;
        height: 250px;
    }

    
    .grid-contact
    {
        display: grid;
        grid-template-columns: repeat(1,1fr);
    }

    .contact-title
    {
        margin-top: 3rem;
    }
    .contact-section h2
    {
        font-size: 3rem;
    }



}


@media screen and (max-width:480px)
{
    html,body
    {   
    font-size: 48.5%;
    }
    .hero-image
    {
        width: 95%;
    }

    .footer-grid
    {
        grid-template-columns: repeat(1,1fr);

    }

    .gallery-section .gallery-img
    {
        width: 200px;
        height: 200px;
    }

    .gallery-section .gallery-grid
    {
        display: grid;
        grid-template-columns: auto ;
        
        justify-content: space-around ;
        
    }
    
}